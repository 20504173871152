.testimonial {
    .section-inner {
        padding-top: $testimonial--padding-t__mobile;
        padding-bottom: $testimonial--padding-b__mobile;
    }

    .section-header {
        padding-bottom: $testimonial-header--padding__mobile;
    }
    .contentwithoutMargin {
        padding-bottom: 15px;
    }

    .tiles-wrap {
        @if ($testimonial-items--padding__mobile != null) {
            margin-right: -($testimonial-items--padding__mobile * 0.5);
            margin-left: -($testimonial-items--padding__mobile * 0.5);
            margin-top: -($testimonial-items--padding__mobile * 0.5);

            &:last-of-type {
                margin-bottom: -($testimonial-items--padding__mobile * 0.5);
            }

            &:not(:last-of-type) {
                margin-bottom: ($testimonial-items--padding__mobile * 0.5);
            }
        }

        &.push-left {
            &::after {
                flex-basis: $testimonial-item--width;
                max-width: $testimonial-item--width;
                @if ($testimonial-items--padding__mobile != null) {
                    padding-left: $testimonial-items--padding__mobile * 0.5;
                    padding-right: $testimonial-items--padding__mobile * 0.5;
                }
            }
        }
    }

    .tiles-item {
        flex-basis: $testimonial-item--width;
        max-width: $testimonial-item--width;
        @if ($testimonial-items--padding__mobile != null) {
            padding: $testimonial-items--padding__mobile * 0.5;
        }
    }

    .tiles-item-inner {
        padding-top: $testimonial-item--inner-padding-v;
        padding-bottom: $testimonial-item--inner-padding-v;
        padding-left: $testimonial-item--inner-padding-h;
        padding-right: $testimonial-item--inner-padding-h;
    }
}

.testimonial-item-content {
    flex-grow: 1;
}

.testimonial-item-source {
    display: inline-flex; // to allow centering when using .content-center on a parent tag
    flex-wrap: wrap;
    align-items: center;
}

@include media(">medium") {
    .testimonial {
        .section-inner {
            padding-top: $testimonial--padding-t__desktop;
            padding-bottom: $testimonial--padding-b__desktop;
        }

        .section-header {
            padding-bottom: $testimonial-header--padding__desktop;
        }
        .contentwithoutMargin {
            padding-bottom: 15px;
        }

        @if ($testimonial-items--padding__desktop != null) {
            .tiles-wrap {
                margin-right: -($testimonial-items--padding__desktop * 0.5);
                margin-left: -($testimonial-items--padding__desktop * 0.5);
                margin-top: -($testimonial-items--padding__desktop * 0.5);

                &:last-of-type {
                    margin-bottom: -(
                            $testimonial-items--padding__desktop * 0.5
                        );
                }

                &:not(:last-of-type) {
                    margin-bottom: ($testimonial-items--padding__desktop * 0.5);
                }

                &.push-left {
                    &::after {
                        padding-left: $testimonial-items--padding__desktop *
                            0.5;
                        padding-right: $testimonial-items--padding__desktop *
                            0.5;
                    }
                }
            }

            .tiles-item {
                padding: $testimonial-items--padding__desktop * 0.5;
            }
        }
    }
}
