.video-container {
    position: relative;
    width: 100%;
    height: 100vh;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-class {
    width: 100%;
    height: 100%;
}

.video-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(44, 44, 44, 0.546);
}
