.google-map {
    width: 100%;
    height: 40vh;
}

.pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: var(--main-blue);
}

.pin-icon {
    font-size: 4rem;
}

.pin-text {
    font-size: 1.3em;
}

@media screen and (min-width: 799px) {
    .google-map {
        height: 40vh;
    }

    .pin {
        width: 15vw;
    }

    .pin-icon {
        font-size: 10vw;
    }
}
